//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";
import {isDev} from "@/config/prototypes";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import {IController} from "@/interfaces/IController";

import SearchService from "@/services/search";

//Interfaces
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";

import {IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute} from "@/interfaces/IUXList";
import {IInfo} from "@/interfaces/IUXInfo";

//Services
import {IFilterClient, IFilterItem} from "@/interfaces/IUXFilters";
import store from "@/store";
import IModuleFilter from "@/interfaces/filters/ModuleFilters";

class SalesController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();

  private search = new SearchService();

  _actionId = MODULE.QUOTATION.ActionId;
  _moduleId = MODULE.QUOTATION.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = new Object() as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = new Object() as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let title = this._base.getTitle("Quote", this._data.referenceNumber, this._data.status);

    return title;
  }

  getSubTitle() {
    let subtitle = this._base.getSubTitle(this._data.name, this._data.location);

    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Create Job
    let newJobAction = {
      id: 1,
      eventType: this.EVENT_ACTION.PROJECTS.NewFromQuote,
      menuTitle: "Create a Job",
      modalTitle: "Create a New Job from Quote",
      componentName: "NewJobFromQuote",
      saveButtonTitle: "Create a Job",
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    //Set Default Action
    this._defaultAction = newJobAction;

    actions.push(newJobAction);

    //Old Work Order Screen
    // let pageTitle = this.getTitle();
    // let earthUrl = this.earth.getViewQuoteDetailsUrl(this._data.id);

    // actions.push({
    //   id: 2,
    //   eventType: this.EVENT_ACTION.PROJECTS.ViewOld,
    //   menuTitle: "View Old Sales Quote",
    //   modalTitle: "View Old Sales Quote",
    //   saveButtonTitle: "Old Sales Quote",
    //   data: this._data,
    //   componentName: "",
    //   url: earthUrl,
    //   UI_TYPE: APP.UI_TYPE.NewTab,
    // });

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    let headerDataObj: ISidePanelHeader = new Object() as ISidePanelHeader;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = true;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = true;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = true;

    headerDataObj.titleSection = new Object() as ILabelTitle;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    headerDataObj.actions = this.getDropDownMenu();

    headerDataObj.defaultAction = this._defaultAction;

    //Action Button
    if (headerDataObj.isActionButtonEnabled) {
      headerDataObj.actions = this.getDropDownMenu();
      headerDataObj.defaultAction = this._defaultAction;
    }

    //Print Button
    if (headerDataObj.isPreviewEnabled) {
      headerDataObj.print = this._base.getPrintAction(this._data.id, MODULE.SALES.ModuleId);
    }

    //Email Button
    if (headerDataObj.isEmailEnabled) {
      headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);
    }
    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    let clientUrl = this.earth.getViewClientNavigationUrl(this._data.intClientID, this._data.businessName);

    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Editing Client",
      saveButtonTitle: "Save Changes",
      data: this._data,
      componentName: "",
      url: clientUrl,
      icon: "fa fa-pencil",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);

    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation
    //If has sales quotes
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrl(this._data.intSalesQuotationID);

      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    let infosection: ISidePanelInfoSection = {
      //Left Side of info section
      leftSection: {
        title: "Due Date:" + this._data.displayDueDate, //Red title H3 font size
        labels: [
          {
            id: 0,
            title: "Start Date: ",
            labelValue: this._data.displayStartDate,
          },
          {
            id: 1,
            title: "Est. Completion Date: ",
            labelValue: this._data.displayEndDate,
          },
        ],
      },

      //Right Side of info section
      rightSection: {
        title: "OVERDUE BY 1 DAY", //Red title H3 font size
        labels: [
          //{ id: 0, title: "Total: ", labelValue: this.$options.filters.currency(project.totalAmonunt) },
          {
            id: 1,
            title: "Created: ",
            labelValue: this._data.displayCreatedDate,
          },
          {id: 2, title: "Created By: ", labelValue: this._data.createdBy},
          // { id: 3, title: "Outstanding Balance: ", labelValue: Vue.$options.filters.currency(project.totalAmonunt) },
        ],
      },
    };

    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    //TAB - Details
    let detailsTab = {
      id: 1,
      title: "Details", //Name of the title
      actionButton: null,
      editButton: null,
      componentName: "SalesTabQuoteDetails",
      // componentPath: "@/components/sales/tab-quote-details-list.vue",
      componentPath: "@/components/sales/tab-quote-details-compiled.vue",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.QUOTATION.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxQuoteType = await this.global.getUXDropDown(MODULE.QUOTATION.UX.QuoteType);

        return {
          uxQuoteType,
        };
      },
    };

    tabs.push(detailsTab);
    //TAB - Equipments
    let infoDetails = this._sidepanel.details as any;
    let equipmentsTab = {
      id: 2,
      title: "Equipment", //Name of the title
      // actionButton: this.getActionButton(MODULE.EQUIPMENT, "new", APP.UI_TYPE.Modal),
      actionButton: null,
      expandListButton: this.getActionButton(MODULE.EQUIPMENT, "expandlist", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.EQUIPMENT, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.EQUIPMENT.ActionId, //Action Id to determine what table list data to return
        clientId: infoDetails.IntClientID,
        locationId: infoDetails.IntLocationID,
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Equipment Type DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventoryCategory = await this.global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory, null, null, null);
        let moduleFilter: IModuleFilter = {
          clientId: infoDetails.IntClientID,
          projectId: 0,
        };
        let uxClientLocation = [];
        //Only call location api if we can filter by client id
        if (moduleFilter.clientId) {
          uxClientLocation = await this.global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, moduleFilter);
        }
        return {
          uxInventoryCategory,
          uxClientLocation,
        };
      },
    };

    tabs.push(equipmentsTab);

    let documentsTab = {
      id: 3,
      title: "Documents/Photos", //Name of the Tab
      actionButton: this.getActionButton(MODULE.DOCUMENTS, "new", APP.UI_TYPE.Modal),
      componentName: "UniversalDocuments",
      componentPath: "@/components/_universal/tab-documents",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.DOCUMENTS.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(documentsTab);

    let jobCosting = {
      id: 7,
      title: "Job Costing", //Name of the Tab
      actionButton: null,
      componentName: "SalesJobCosting",
      componentPath: "@/components/sales/tab-job-costing.vue",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.EQUIPMENT.ActionId, //Action Id to determine what table list data to return
        clientId: infoDetails.IntClientID,
        locationId: infoDetails.IntLocationID,
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      uxdata: async (pageName: string): Promise<Object> => {
        let uxQuoteType = await this.global.getUXDropDown(MODULE.QUOTATION.UX.QuoteType);

        return {
          uxQuoteType,
        };
      },
    };

    tabs.push(jobCosting);

    // //TAB - Notes
    let notesTab = {
      id: 4,
      title: "Notes", //Name of the Tab
      actionButton: null,
      componentName: "NotesUniversal",
      componentPath: "@/components/_universal/notes-universal",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.NOTES.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(notesTab);

    // //TAB - Terms & Conditions
    let termsTab = {
      id: 5,
      title: "Terms & Conditions", //Name of the Tab
      actionButton: null,
      componentName: "SalesTerms",
      componentPath: "@/components/sales/tab-terms.vue",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.TEMPLATES.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(termsTab);

    // //TAB - Additional Options
    // let optionsTab = {
    //     id: 5,
    //     title: "Additional Options", //Name of the Tab
    //     actionButton: null,
    //     componentName: "SalesOptions",
    //     componentPath: "@/components/sales/tab-options.vue",
    //     param: {   //Dynamically passs any additional paramaters required for api calls etc.
    //         id: this._data.id,
    //         parentActionId: 0,  //Parent Table the actionId table must be filtered on, by Id
    //         actionId: 0, //Action Id to determine what table list data to return
    //     }, //Action Id to determine what table list data to return
    //     data: this._data, //Any Data need passing to the modal box
    //     UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    // };

    // tabs.push(optionsTab);

    //TAB - History
    let historyTab = {
      id: 6,
      title: "History", //Name of the Tab
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
        moduleId: MODULE.QUOTATION.ModuleId,
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(historyTab);

    let settingsTab = {
      id: 9,
      title: "Settings/Options", //Name of the Tab
      actionButton: null,
      componentName: "SettingsOptions",
      componentPath: "@/components/sales/settings-tab",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.QUOTATION.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.QUOTATION.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      uxdata: async (pageName: string): Promise<Object> => {
        return {};
      },
    };
    tabs.push(settingsTab);

    return tabs;
  }

  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    let eventType: any = this.EVENT_ACTION.DOCUMENTS;

    //Set Billing Rates Tab Details
    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS_PHOTOS;

      if (actionType === "new") {
        title = "Add Documents/Photos";
        componentName = this.EVENT_ACTION.DOCUMENTS_PHOTOS.New;
      } else if (actionType === "edit") {
        title = "Preview Document/Photo";
        componentName = this.EVENT_ACTION.IFRAME.PreviewImage;
      }
    }

    //Set Contacts Tab Details
    if (module === MODULE.EQUIPMENT) {
      eventType = this.EVENT_ACTION.EQUIPMENT;

      if (actionType === "new") {
        title = "Add New Equipment";
        componentName = "NewEquipment";
      } else if (actionType === "edit") {
        title = "Edit New Equipment";
        componentName = "NewEquipment";
      } else if (actionType === "expandlist") {
        title = "Equipment List";
        componentName = "ExpandEquipmentList";
      }
    }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        parentActionId: this._actionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
        moduleId: this._moduleId,
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    }
  }

  getListHeaders(): ITableHeader[] {
    let header: ITableHeader[] = [];

    header.push({
      id: 0,
      title: "Quote #",
      columnName: "referenceNumber",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 1,
      title: "Status",
      columnName: "htmlBadge1",
      isSortable: false,
      isVisible: true,
      isHtmlColumn: true,
    });

    header.push({
      id: 2,
      title: "Prospects/Clients",
      columnName: "name",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 3,
      title: "Location",
      columnName: "location",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 4,
      title: "Total",
      columnName: "total",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 5,
      title: "Approved",
      columnName: "approvedTotal",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 6,
      title: "Created By",
      columnName: "createdBy",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 7,
      title: "Created Date",
      columnName: "createdDate",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    return header;
  }

  getHoverMenu(): ITableHoverMenu {
    let hover = new Object() as ITableHoverMenu;

    //Not implemented for now
    hover.isEnabled = false;

    return hover;
  }

  getActions(): ITableAction {
    let actions = new Object() as ITableAction;

    //Not implemented for now
    actions.isEnabled = false;

    return actions;
  }

  //Info Summery
  async getInfoDetails(id): Promise<IInfo> {
    try {
      let info = new Object() as IInfo;

      //Not implemented for now
      info.isEnabled = false;

      let filter = {
        actionId: this._actionId,
        id: id,
      };

      let results = await this.search.getResults("global/details/info", filter);

      info.data = results.resources;

      info.isDetailsView = true;

      return info;
    } catch (error) {}
  }

  //Call back function - to Load UX Dropdown List
  async loadDropDownList() {
    let uxClientLocation;
    let uxClientProspect;
    let uxQuoteStatus;
    let uxQuoteType;
    let uxSalesRep;
    let uxQuoteTemplates;

    this._sidepanel.uxdata = async (pageName: string, id): Promise<Object> => {
      //Only call location api if we can filter by client id
      let moduleFilter: IModuleFilter = {
        clientId: id,
        projectId: 0,
      };

      //Only call location api if we can filter by client id
      if (moduleFilter.clientId) {
        uxClientLocation = await this.global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, moduleFilter);
      }

      if (pageName == APP.PAGE.Sales.Details) {
        uxClientProspect = await this.global.getUXDropDown(MODULE.CLIENTS.ActionId);
        uxQuoteStatus = await this.global.getUXDropDown(MODULE.QUOTATION.UX.QuoteStatus);
        uxQuoteType = await this.global.getUXDropDown(MODULE.QUOTATION.UX.QuoteType);
        uxQuoteTemplates = await this.global.getUXDropDown(MODULE.TEMPLATES.UX.Templates, MODULE.SALES.ModuleId);
        uxSalesRep = await this.global.getUXDropDown(MODULE.CLIENTS.UX.SalesRep);
      }
      return {
        uxClientProspect,
        uxClientLocation,
        uxQuoteStatus,
        uxQuoteType,
        uxQuoteTemplates,
        uxSalesRep,
      };
    };
  }

  //Info stats returned at the top of every list page.
  async getInfoList(): Promise<IInfo> {
    let info = new Object() as IInfo;

    //Not implemented for now
    info.isEnabled = false;

    let filter = {
      actionId: this._actionId,
    };

    let results = await this.search.getResults("global/list/info", filter);

    info.data = results.resources;

    return info;
  }

  //Dynamically determin the filters for each list page...
  async getFilters(): Promise<IFilterClient> {
    let filter = new Object() as IFilterClient;
    let filters: IFilterItem[] = [];

    //If the advance filter button options should show
    filter.hasAdvancedFilter = false;

    //Not implemented for now
    filter.isEnabled = false;

    //Filter by client
    filters.push({
      id: 0,
      title: "Quote #",
      name: "quotenumber",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "qutotenumber",
      isfilterByDisplayName: true,
    });

    //Filter by client
    filters.push({
      id: 1,
      title: "Prospect/Client/Address",
      name: "businessname",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "businessname",
      isfilterByDisplayName: true,
    });

    //Filter by Industry
    filters.push({
      id: 2,
      title: "Status",
      name: "status",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.QUOTATION.UX.QuoteStatus, null, null, null),
      selectedValue: 1,
      defaultValue: 1,
      query: "q.intquotestatusId",
    });

    filters.push({
      id: 3,
      title: "Sales Rep.",
      name: "IntSalesRepUserID",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.CLIENTS.UX.SalesRep),
      selectedValue: 1,
      defaultValue: 1,
      query: "q.IntSalesRepUserID",
    });

    filters.push({
      id: 4,
      title: "Created By",
      name: "createdby",
      uxtype: "dropdown",
      data: await this.global.getUXDropDown(MODULE.CLIENTS.UX.SalesRep),
      selectedValue: 1,
      defaultValue: 1,
      query: "q.createdBy",
      isfilterByGuid: true,
    });

    filter.filters = filters;

    return filter;
  }

  async getListResults(filter) {
    filter.actionId = this._actionId;

    return await this.search.getResults("global/list", filter);
  }

  // async getDownloadLink(filter) {
  //   filter.actionId = this._actionId;
  //   return await this.search.getResults("global/list", {...filter, export: "excel"});
  // }

  //LIST - Entry for list page
  async list(): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    list.actionId = this._actionId;

    //settings
    list.settings = {
      isHoverOverRowEnabled: true,
    };

    //Table Header Properties
    list.table = new Object() as ITable;
    list.table.header = this.getListHeaders();

    //Data
    list.table.data = await this.getListResults({});

    //List Table
    list.info = await this.getInfoList();

    //True - if we should load old ProjectEarth implementation via Iframe
    list.isSidePanelFrame = false;

    //Filters
    list.filter = await this.getFilters();

    //Hover Menu
    list.table.hover = new Object() as ITableHoverMenu;
    list.table.hover = this.getHoverMenu();

    //More Options - Action Menu
    list.table.actions = new Object() as ITableAction;
    list.table.actions = this.getActions();

    //Edit Action - i.e. when user clicks Edit, what should happen.
    list.editAction = new Object() as IEditAction;
    list.editAction.route = new Object() as IRoute;
    list.editAction.route = {
      name: "quote-details",
      path: "/sales/details",
      param: {
        id: 0, //Id will be set once the record has been clicked
      },
    };

    return list;
  }

  //MAIN - Entry for Details page
  async main(data): Promise<IUXSidePanel> {
    this._data = data;
    this._data.typeId = MODULE.NOTES.SALES;

    this._sidepanel = store.getters.getSidePanel;
    this._sidepanel.actionId = this._actionId;

    //Specify the name of the page being loaded
    this._sidepanel.pageName = APP.PAGE.Sales.Details;

    //Header
    this._sidepanel.headerDataObj = this.getHeader();

    //Decide if Side panel should show Iframe or load component view
    if (this._sidepanel.isSidePanelFrame) {
      this._sidepanel.url = this.earth.getViewQuoteDetailsUrl(this._data.id);
    } //IF not an IFrame then load standard data component
    else {
      //Load details data
      this._sidepanel.details = await this.global.getEditDetails(this._actionId, data.id);

      //Action Bar
      this._sidepanel.actionBarDataObj = this.getActionBar();

      //Info Section
      this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();

      //Tabs
      this._sidepanel.tabSectionDataObj = this.getTabSection();
      this._sidepanel.moduleId = this._moduleId;

      //UX Dropdown List - Call back function
      this.loadDropDownList();

      //Info Stats
      this._sidepanel.info = await this.getInfoDetails(data.id);
    }

    return this._sidepanel;
  }
}

export default SalesController;
